.Grid {
  margin: 0 auto;
}

.Background {
  display: none;
}

.LandingSection {
  background: transparent radial-gradient( circle at top right, #A5CFFF 0%, #FFC9FF 80%) 0% 0% no-repeat padding-box;
  height: 100vh;
}

.Landing {
  width: 80%;
  margin: 0 auto;
  padding-top: 55vh;
  color: #425F83;
}

.Landing h1 {
  margin: 0px;
  font-size: 8vw;
}

.Landing h4 {
  font-weight: 500;
}

.Scroll {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Scroll span {
  margin-right: 10px;
}

@media only screen and (min-width: 768px) {
  .LandingSection {
    background: none;
  }
  .Background {
    display: block;
    position: absolute;
    left: 10%;
    top: 10%;
    width: 80%;
    height: 80vh;
    padding: 50px;
    background: transparent radial-gradient( circle at top right, #A5CFFF 0%, #FFC9FF 80%) 0% 0% no-repeat padding-box;
    opacity: 1;
    z-index: -10;
  }
  .Landing {
    padding-top: 30vh;
  }
  .Landing h1 {
    padding: 100px 0 0 0;
    margin: 0;
    font-size: 8vw;
    letter-spacing: 10px;
    color: #ffffff;
  }
  .Landing h4 {
    padding-right: 45%;
    font-size: 1.25vw;
    margin: 0;
    color: #ffffff;
  }
  .Scroll {
    color: #495075;
    margin-top: 28vh;
    margin-bottom: 100px;
  }
}

@media only screen and (min-width: 1440px) {
  .Background {
    width: 70%;
    left: 15%;
  }
}