.Name {
  display: none;
}

@media only screen and (min-width: 768px) {
  .GridContainer {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 16px;
  }

  .Landing {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    grid-area: 'landing';
  }

  .Name {
    display: block;
    position: relative;
    top: 5vh;
    left: 2.5vw;
    grid-area: 'svg';
  }

  .Projects {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-area: 'projects';
  }
}
