.Project {
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.View {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 300px;
    border-radius: 10px 10px 0 0; 
    overflow: hidden;
}

.View:hover {
    cursor: pointer;
}


.View img {
    height: 90%;
    width: auto;
}

.View:hover img {
    display: none;
}

.View span {
    position: absolute;
    padding: 20px 20px;
    background-color: #5c779b;
    color: #ffffff;
    border-radius: 10px;
    z-index: 100;
    opacity: 0;
    transition: 0.3s ease-in;
}

.View:hover span {
    opacity: 1;
}

.Details {
    padding: 30px 30px 20px;
}

.Details p {
    font-family: Helvetica, sans-serif;
}

.Details h5 {
    margin: 0 0 10px 0;
}

.Skills {
    display: flex;
    flex-wrap: wrap;
}

.Skills span{
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 12px 12px;
    border-radius: 10px;
    border: 3px solid #5c779bdc;
    color: #5c779b;
    font-size: 14px;
}

.Skills span:last-child{
    margin-right: 0;
}