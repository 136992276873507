.Projects {
  grid-area: 'projects';
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ProjectSection {
  width: 80%;
  margin: 20px auto;
}

.ProjectsView {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.Link {
  text-decoration: none;
  font-weight: 600;
  color: #371e55;
  transition: all .5s ease-out;
}

.Link:hover {
  color: #ab85dd;
}

.Links {
  padding: 20px 0;
  text-align: center;
}

@media only screen and (min-width: 1024px) {
  .Projects {
    grid-area: 'projects';
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .ProjectsView {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 1440px) {
  .ProjectSection {
    width: 60%;
  }
}

@media only screen and (min-width: 2560px) {
  .ProjectSection {
    width: 60%;
  }
}