.Button {
    padding: 35px 25px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    background-color: #ffffff;
    border-radius: 10px;
    display: inline-block;
    color: #000000;
    text-decoration: none;
    transition: all .2s ease-out;
}

.Spotify:hover {
    background-color: #289c0b;
    color: #ffffff;
}

.Medium:hover {
    background-color: #000000;
    color: #ffffff;
}

.SoundCloud:hover {
    background-color: #ff7700;
    color: #ffffff;
}

.Button span {
    margin-left: 15px;
}

@media only screen and (min-width: 768px) {
  .Button {
    transition: all .5s ease-out; 
  }
}