@import url(https://use.typekit.net/key0bfb.css);
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0 auto;
  background-color: #ffffff;
  color: #525252;
  font-family: museo-sans, Helvetica, sans-serif;
  font-weight: 500;
  font-style: normal;
}

*, *:before, *:after {
  box-sizing: inherit;
}


.Layout_Spacer__3a61h {
  display: none;
}

@media only screen and (min-width: 768px) {
  .Layout_Spacer__3a61h {
    height: 150px;
  }
}
.Landing_Grid__uWosM {
  margin: 0 auto;
}

.Landing_Background__3aRX0 {
  display: none;
}

.Landing_LandingSection__J0EKo {
  background: transparent radial-gradient( circle at top right, #A5CFFF 0%, #FFC9FF 80%) 0% 0% no-repeat padding-box;
  height: 100vh;
}

.Landing_Landing__3WE0r {
  width: 80%;
  margin: 0 auto;
  padding-top: 55vh;
  color: #425F83;
}

.Landing_Landing__3WE0r h1 {
  margin: 0px;
  font-size: 8vw;
}

.Landing_Landing__3WE0r h4 {
  font-weight: 500;
}

.Landing_Scroll__2YNHP {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Landing_Scroll__2YNHP span {
  margin-right: 10px;
}

@media only screen and (min-width: 768px) {
  .Landing_LandingSection__J0EKo {
    background: none;
  }
  .Landing_Background__3aRX0 {
    display: block;
    position: absolute;
    left: 10%;
    top: 10%;
    width: 80%;
    height: 80vh;
    padding: 50px;
    background: transparent radial-gradient( circle at top right, #A5CFFF 0%, #FFC9FF 80%) 0% 0% no-repeat padding-box;
    opacity: 1;
    z-index: -10;
  }
  .Landing_Landing__3WE0r {
    padding-top: 30vh;
  }
  .Landing_Landing__3WE0r h1 {
    padding: 100px 0 0 0;
    margin: 0;
    font-size: 8vw;
    letter-spacing: 10px;
    color: #ffffff;
  }
  .Landing_Landing__3WE0r h4 {
    padding-right: 45%;
    font-size: 1.25vw;
    margin: 0;
    color: #ffffff;
  }
  .Landing_Scroll__2YNHP {
    color: #495075;
    margin-top: 28vh;
    margin-bottom: 100px;
  }
}

@media only screen and (min-width: 1440px) {
  .Landing_Background__3aRX0 {
    width: 70%;
    left: 15%;
  }
}
.Project_Project__3w1yQ {
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.Project_View__1iquu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 300px;
    border-radius: 10px 10px 0 0; 
    overflow: hidden;
}

.Project_View__1iquu:hover {
    cursor: pointer;
}


.Project_View__1iquu img {
    height: 90%;
    width: auto;
}

.Project_View__1iquu:hover img {
    display: none;
}

.Project_View__1iquu span {
    position: absolute;
    padding: 20px 20px;
    background-color: #5c779b;
    color: #ffffff;
    border-radius: 10px;
    z-index: 100;
    opacity: 0;
    transition: 0.3s ease-in;
}

.Project_View__1iquu:hover span {
    opacity: 1;
}

.Project_Details__16ysr {
    padding: 30px 30px 20px;
}

.Project_Details__16ysr p {
    font-family: Helvetica, sans-serif;
}

.Project_Details__16ysr h5 {
    margin: 0 0 10px 0;
}

.Project_Skills__UJOp3 {
    display: flex;
    flex-wrap: wrap;
}

.Project_Skills__UJOp3 span{
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 12px 12px;
    border-radius: 10px;
    border: 3px solid #5c779bdc;
    color: #5c779b;
    font-size: 14px;
}

.Project_Skills__UJOp3 span:last-child{
    margin-right: 0;
}
.Projects_Projects__1ECks {
  grid-area: 'projects';
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Projects_ProjectSection__1PvtI {
  width: 80%;
  margin: 20px auto;
}

.Projects_ProjectsView__V27Xp {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 20px;
}

.Projects_Link__xyFah {
  text-decoration: none;
  font-weight: 600;
  color: #371e55;
  transition: all .5s ease-out;
}

.Projects_Link__xyFah:hover {
  color: #ab85dd;
}

.Projects_Links__2-r-_ {
  padding: 20px 0;
  text-align: center;
}

@media only screen and (min-width: 1024px) {
  .Projects_Projects__1ECks {
    grid-area: 'projects';
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .Projects_ProjectsView__V27Xp {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 1440px) {
  .Projects_ProjectSection__1PvtI {
    width: 60%;
  }
}

@media only screen and (min-width: 2560px) {
  .Projects_ProjectSection__1PvtI {
    width: 60%;
  }
}
.Button_Button__1Qi6x {
    padding: 35px 25px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    background-color: #ffffff;
    border-radius: 10px;
    display: inline-block;
    color: #000000;
    text-decoration: none;
    transition: all .2s ease-out;
}

.Button_Spotify__TAkl9:hover {
    background-color: #289c0b;
    color: #ffffff;
}

.Button_Medium__v8JLK:hover {
    background-color: #000000;
    color: #ffffff;
}

.Button_SoundCloud__NpXsx:hover {
    background-color: #ff7700;
    color: #ffffff;
}

.Button_Button__1Qi6x span {
    margin-left: 15px;
}

@media only screen and (min-width: 768px) {
  .Button_Button__1Qi6x {
    transition: all .5s ease-out; 
  }
}
.About_About__scHdH {
  width: 80%;
  margin: 0 auto;
  padding: 50px 0px 50px 0px;
  color: #ffffff;
  font-weight: 300;
}

.About_ProfileImage__dPBMv {
  width: 75%;
  height: 100%;
  border-radius: 50%
}

.About_Details__1YxQY a {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  font-size: 14px;
}

@media only screen and (min-width: 425px) {
  .About_ProfileImage__dPBMv {
    width: 35%;
  }
}

@media only screen and (min-width: 1024px) {
  .About_Container__1tPbZ {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-gap: 16px;
    gap: 16px;
  }

  .About_ProfileImage__dPBMv {
    width: 250px;
    height: 250px;
  }

  .About_Details__1YxQY {
    grid-column-start: 3;
    grid-column-end: 9;
  }

  .About_Details__1YxQY a {
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .About_Details__1YxQY a:last-child {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 1440px) {
  .About_Details__1YxQY a {
    width: auto;
    width: initial;
  }
}

@media only screen and (min-width: 2560px) {
  .About_About__scHdH {
    width: 60%;
  }
}
.Footer_Footer__238hM {
  background-color: #606da3;
  color: #ffffff;
}

.Footer_Wrapper__1bsCi {
  margin: 0 auto;
  width: 80%;
  padding: 75px 0px;
}

.Footer_Footer__238hM a {
  padding: 25px 75px;
  width: 100%;
  text-align: center;
  margin-bottom: 12px;
  background-color: #ffbb89;
  border-radius: 10px;
  text-decoration: none;
  font-size: 18px;
  color: #ffffff;
  transition: all .3s ease-out;
}

.Footer_Footer__238hM a:last-child {
  margin-bottom: 0px;
}

.Footer_LinksContainer__3NyR2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h3 {
  text-align: center;
  margin: 0 0 50px 0
}

@media only screen and (min-width: 768px) {
  .Footer_Wrapper__1bsCi {
    width: 60%;
  }

  .Footer_Footer__238hM a {
    margin-bottom: 0px;
    margin-right: 16px;
  }

  .Footer_Footer__238hM a:last-child {
    margin-right: 0px;
  }
  .Footer_Footer__238hM a:hover {
    background-color: #e69052;
    text-decoration: none;
    color: #ffffff;
  }

  .Footer_LinksContainer__3NyR2 { 
    flex-direction: row;
  }
}
.Portfolio_Name__2yeKK {
  display: none;
}

@media only screen and (min-width: 768px) {
  .Portfolio_GridContainer__14ioS {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 16px;
    gap: 16px;
  }

  .Portfolio_Landing__QwXuY {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    grid-area: 'landing';
  }

  .Portfolio_Name__2yeKK {
    display: block;
    position: relative;
    top: 5vh;
    left: 2.5vw;
    grid-area: 'svg';
  }

  .Portfolio_Projects__2hwCf {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-area: 'projects';
  }
}

